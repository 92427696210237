var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reports"},[_c('div',{staticClass:"tile"},[_c('ValidationObserver',{ref:"HTMLReportForm",staticClass:"mt-0",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit, valid }){return [_c('h5',{staticClass:"tile-title mb-4"},[_vm._v("Convert Report (HTML)")]),_c('ValidationProvider',{staticClass:"mb-3",attrs:{"tag":"div","rules":"required","name":"Title"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"col-form-label",attrs:{"for":"title"}},[_vm._v("Title:")]),_c('input',{staticClass:"form-control",class:{
            'form-control': true,
            'is-invalid': errors[0],
          },attrs:{"type":"text","id":"title","readonly":""},domProps:{"value":_vm.selectedFile.title}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{ref:"file",staticClass:"mb-3",attrs:{"tag":"div","rules":"required|mimes:csv","name":"file"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"col-form-label",attrs:{"for":"file"}},[_vm._v("CSV File:")]),_c('input',{staticClass:"form-control",class:{
            'form-control': true,
            'is-invalid': errors[0],
          },attrs:{"id":"reportFile","type":"file"},on:{"change":_vm.checkFile}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('div',{staticClass:"d-grid d-md-flex justify-content-md-end"},[_c('button',{staticClass:"btn btn-secondary me-md-2",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.resetHTMLForm.apply(null, arguments)}}},[_vm._v(" Reset ")]),_c('button',{staticClass:"btn btn-primary me-md-2",attrs:{"type":"submit","disabled":!valid || _vm.isLoading},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.uploadFile)}}},[_vm._v(" Download "),_c('loader',{attrs:{"isLoading":_vm.isLoading}})],1)])]}}])})],1),_c('div',{staticClass:"tile"},[_c('ValidationObserver',{ref:"CSVReportForm",staticClass:"mt-0",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit, valid }){return [_c('h5',{staticClass:"tile-title mb-4"},[_vm._v("Generate Report (CSV)")]),_c('ValidationProvider',{staticClass:"mb-3",attrs:{"tag":"div","rules":"required","name":"Title"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"col-form-label",attrs:{"for":"title"}},[_vm._v("Title:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedCSVFile.title),expression:"selectedCSVFile.title"}],staticClass:"form-control",class:{
            'form-control': true,
            'is-invalid': errors[0],
          },attrs:{"type":"text","id":"csvFileTitle"},domProps:{"value":(_vm.selectedCSVFile.title)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.selectedCSVFile, "title", $event.target.value)}}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"mb-3",attrs:{"tag":"div","rules":"required","name":"Date"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dateRange.startDate),expression:"dateRange.startDate"}],staticClass:"form-control",attrs:{"id":"date","type":"text","hidden":""},domProps:{"value":(_vm.dateRange.startDate)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.dateRange, "startDate", $event.target.value)}}}),_c('date-range-picker',{ref:"picker",class:{
            dropdownError: errors[0],
          },staticStyle:{"width":"100%"},attrs:{"opens":"right","locale-data":{
            firstDay: 1,
            format: 'dd-mm-yyyy',
            applyLabel: 'Select',
          },"ranges":false,"appendToBody":true,"show-dropdowns":true,"singleDatePicker":true},on:{"update":_vm.selectDate},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('i',{staticClass:"glyphicon glyphicon-calendar fa fa-calendar"}),_vm._v(" "+_vm._s(_vm.csvDates)+" ")]},proxy:true}],null,true),model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('div',{staticClass:"d-grid d-md-flex justify-content-md-end"},[_c('button',{staticClass:"btn btn-secondary me-md-2",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.resetCSVForm.apply(null, arguments)}}},[_vm._v(" Reset ")]),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit","disabled":!valid || _vm.isLoading},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.downloadCSV)}}},[_vm._v(" Download "),_c('loader',{attrs:{"isLoading":_vm.isLoading}})],1)])]}}])})],1),_c('div',{staticClass:"tile"},[_c('ValidationObserver',{ref:"CRSReportForm",staticClass:"mt-0",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit, valid }){return [_c('h5',{staticClass:"tile-title mb-4"},[_vm._v("Generate CRS Report (CSV)")]),_c('ValidationProvider',{staticClass:"mb-3",attrs:{"tag":"div","rules":"required","name":"Date"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.crsDateRange.startDate),expression:"crsDateRange.startDate"}],staticClass:"form-control",attrs:{"id":"date","type":"text","hidden":""},domProps:{"value":(_vm.crsDateRange.startDate)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.crsDateRange, "startDate", $event.target.value)}}}),_c('date-range-picker',{ref:"picker",class:{
            dropdownError: errors[0],
          },staticStyle:{"width":"100%"},attrs:{"opens":"right","locale-data":{
            firstDay: 1,
            format: 'dd-mm-yyyy',
            applyLabel: 'Select',
          },"ranges":false,"appendToBody":true,"show-dropdowns":true},on:{"update":_vm.selectCRSDate},scopedSlots:_vm._u([{key:"input",fn:function(picker){return [_c('i',{staticClass:"glyphicon glyphicon-calendar fa fa-calendar"}),(picker.startDate && picker.endDate)?_c('span',[_vm._v(" "+_vm._s(_vm.crsDates)+" ")]):_vm._e()]}}],null,true),model:{value:(_vm.crsDateRange),callback:function ($$v) {_vm.crsDateRange=$$v},expression:"crsDateRange"}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('div',{staticClass:"d-grid d-md-flex justify-content-md-end"},[_c('button',{staticClass:"btn btn-secondary me-md-2",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.resetCRSForm.apply(null, arguments)}}},[_vm._v(" Reset ")]),_c('button',{staticClass:"btn btn-primary me-md-2",attrs:{"type":"submit","disabled":!valid || _vm.isLoading},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.generateCRSReport)}}},[_vm._v(" Download "),_c('loader',{attrs:{"isLoading":_vm.isLoading}})],1)])]}}])})],1),_c('div',{staticClass:"tile"},[_c('ValidationObserver',{ref:"XMLReportForm",staticClass:"mt-0",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit, valid }){return [_c('h5',{staticClass:"tile-title mb-4"},[_vm._v("Convert Report (XML)")]),_c('ValidationProvider',{staticClass:"mb-3",attrs:{"tag":"div","rules":"required","name":"Title"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"col-form-label",attrs:{"for":"title"}},[_vm._v("Title:")]),_c('input',{staticClass:"form-control",class:{
            'form-control': true,
            'is-invalid': errors[0],
          },attrs:{"type":"text","id":"title","readonly":""},domProps:{"value":_vm.selectedFile.title}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{ref:"file",staticClass:"mb-3",attrs:{"tag":"div","rules":"required|mimes:csv","name":"file"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"col-form-label",attrs:{"for":"file"}},[_vm._v("CSV File:")]),_c('input',{staticClass:"form-control",class:{
            'form-control': true,
            'is-invalid': errors[0],
          },attrs:{"id":"reportXMLFile","type":"file"},on:{"change":_vm.checkFile}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('div',{staticClass:"d-grid d-md-flex justify-content-md-end"},[_c('button',{staticClass:"btn btn-secondary me-md-2",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.resetXMLForm.apply(null, arguments)}}},[_vm._v(" Reset ")]),_c('button',{staticClass:"btn btn-primary me-md-2",attrs:{"type":"submit","disabled":!valid},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.generateXMLReport)}}},[_vm._v(" Download "),_c('loader',{attrs:{"isLoading":_vm.isLoading}})],1)])]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }