<template>
  <div class="reports">
    <div class="tile">
      <ValidationObserver
        tag="form"
        v-slot="{ handleSubmit, valid }"
        ref="HTMLReportForm"
        class="mt-0"
      >
        <h5 class="tile-title mb-4">Convert Report (HTML)</h5>
        <ValidationProvider
          tag="div"
          rules="required"
          name="Title"
          v-slot="{ errors }"
          class="mb-3"
        >
          <label for="title" class="col-form-label">Title:</label>
          <input
            type="text"
            class="form-control"
            :value="selectedFile.title"
            id="title"
            :class="{
              'form-control': true,
              'is-invalid': errors[0],
            }"
            readonly
          />
          <span class="error">{{ errors[0] }}</span>
        </ValidationProvider>
        <ValidationProvider
          ref="file"
          tag="div"
          rules="required|mimes:csv"
          name="file"
          v-slot="{ errors }"
          class="mb-3"
        >
          <label for="file" class="col-form-label">CSV File:</label>
          <input
            id="reportFile"
            class="form-control"
            type="file"
            @change="checkFile"
            :class="{
              'form-control': true,
              'is-invalid': errors[0],
            }"
          />
          <span class="error">{{ errors[0] }}</span>
        </ValidationProvider>
        <div class="d-grid d-md-flex justify-content-md-end">
          <button
            class="btn btn-secondary me-md-2"
            type="button"
            @click.prevent="resetHTMLForm"
          >
            Reset
          </button>
          <button
            type="submit"
            class="btn btn-primary me-md-2"
            :disabled="!valid || isLoading"
            @click.prevent="handleSubmit(uploadFile)"
          >
            Download
            <loader :isLoading="isLoading"></loader>
          </button>
        </div>
      </ValidationObserver>
    </div>
    <div class="tile">
      <ValidationObserver
        tag="form"
        v-slot="{ handleSubmit, valid }"
        ref="CSVReportForm"
        class="mt-0"
      >
        <h5 class="tile-title mb-4">Generate Report (CSV)</h5>
        <ValidationProvider
          tag="div"
          rules="required"
          name="Title"
          v-slot="{ errors }"
          class="mb-3"
        >
          <label for="title" class="col-form-label">Title:</label>
          <input
            type="text"
            class="form-control"
            v-model="selectedCSVFile.title"
            id="csvFileTitle"
            :class="{
              'form-control': true,
              'is-invalid': errors[0],
            }"
          />
          <span class="error">{{ errors[0] }}</span>
        </ValidationProvider>
        <ValidationProvider
          tag="div"
          rules="required"
          name="Date"
          v-slot="{ errors }"
          class="mb-3"
        >
          <input
            id="date"
            type="text"
            class="form-control"
            v-model="dateRange.startDate"
            hidden
          />
          <date-range-picker
            style="width: 100%"
            ref="picker"
            opens="right"
            :locale-data="{
              firstDay: 1,
              format: 'dd-mm-yyyy',
              applyLabel: 'Select',
            }"
            :ranges="false"
            :appendToBody="true"
            :show-dropdowns="true"
            :singleDatePicker="true"
            :class="{
              dropdownError: errors[0],
            }"
            v-model="dateRange"
            @update="selectDate"
          >
            <template v-slot:input>
              <i class="glyphicon glyphicon-calendar fa fa-calendar"></i>
              {{ csvDates }}
            </template>
          </date-range-picker>
          <span class="error">{{ errors[0] }}</span>
        </ValidationProvider>
        <div class="d-grid d-md-flex justify-content-md-end">
          <button
            class="btn btn-secondary me-md-2"
            type="button"
            @click.prevent="resetCSVForm"
          >
            Reset
          </button>
          <button
            type="submit"
            class="btn btn-primary"
            :disabled="!valid || isLoading"
            @click.prevent="handleSubmit(downloadCSV)"
          >
            Download
            <loader :isLoading="isLoading"></loader>
          </button>
        </div>
      </ValidationObserver>
    </div>
    <div class="tile">
      <ValidationObserver
        tag="form"
        v-slot="{ handleSubmit, valid }"
        ref="CRSReportForm"
        class="mt-0"
      >
        <h5 class="tile-title mb-4">Generate CRS Report (CSV)</h5>
        <ValidationProvider
          tag="div"
          rules="required"
          name="Date"
          v-slot="{ errors }"
          class="mb-3"
        >
          <input
            id="date"
            type="text"
            class="form-control"
            v-model="crsDateRange.startDate"
            hidden
          />
          <date-range-picker
            style="width: 100%"
            ref="picker"
            opens="right"
            :locale-data="{
              firstDay: 1,
              format: 'dd-mm-yyyy',
              applyLabel: 'Select',
            }"
            :ranges="false"
            :appendToBody="true"
            :show-dropdowns="true"
            :class="{
              dropdownError: errors[0],
            }"
            v-model="crsDateRange"
            @update="selectCRSDate"
          >
            <template v-slot:input="picker">
              <i class="glyphicon glyphicon-calendar fa fa-calendar"></i>
              <span v-if="picker.startDate && picker.endDate">
                {{ crsDates }}
              </span>
            </template>
          </date-range-picker>
          <span class="error">{{ errors[0] }}</span>
        </ValidationProvider>
        <div class="d-grid d-md-flex justify-content-md-end">
          <button
            class="btn btn-secondary me-md-2"
            type="button"
            @click.prevent="resetCRSForm"
          >
            Reset
          </button>
          <button
            type="submit"
            class="btn btn-primary me-md-2"
            :disabled="!valid || isLoading"
            @click.prevent="handleSubmit(generateCRSReport)"
          >
            Download
            <loader :isLoading="isLoading"></loader>
          </button>
        </div>
      </ValidationObserver>
    </div>
    <div class="tile">
      <ValidationObserver
        tag="form"
        v-slot="{ handleSubmit, valid }"
        ref="XMLReportForm"
        class="mt-0"
      >
        <h5 class="tile-title mb-4">Convert Report (XML)</h5>
        <ValidationProvider
          tag="div"
          rules="required"
          name="Title"
          v-slot="{ errors }"
          class="mb-3"
        >
          <label for="title" class="col-form-label">Title:</label>
          <input
            type="text"
            class="form-control"
            :value="selectedFile.title"
            id="title"
            :class="{
              'form-control': true,
              'is-invalid': errors[0],
            }"
            readonly
          />
          <span class="error">{{ errors[0] }}</span>
        </ValidationProvider>
        <ValidationProvider
          ref="file"
          tag="div"
          rules="required|mimes:csv"
          name="file"
          v-slot="{ errors }"
          class="mb-3"
        >
          <label for="file" class="col-form-label">CSV File:</label>
          <input
            id="reportXMLFile"
            class="form-control"
            type="file"
            @change="checkFile"
            :class="{
              'form-control': true,
              'is-invalid': errors[0],
            }"
          />
          <span class="error">{{ errors[0] }}</span>
        </ValidationProvider>
        <div class="d-grid d-md-flex justify-content-md-end">
          <button
            class="btn btn-secondary me-md-2"
            type="button"
            @click.prevent="resetXMLForm"
          >
            Reset
          </button>
          <button
            type="submit"
            class="btn btn-primary me-md-2"
            :disabled="!valid"
            @click.prevent="handleSubmit(generateXMLReport)"
          >
            Download
            <loader :isLoading="isLoading"></loader>
          </button>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import { mutations } from "../store";
import loader from "../components/loader";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
  data() {
    return {
      selectedFile: {
        title: "",
        file: null,
      },
      dateRange: {
        startDate: null,
        endDate: null,
      },
      crsDateRange: {
        startDate: null,
        endDate: null,
      },
      selectedCSVFile: {
        title: "",
        dates: [],
      },
      isLoading: false,
    };
  },
  components: {
    loader,
    DateRangePicker,
  },
  computed: {
    csvDates() {
      return this.selectedCSVFile.dates
        .map((date) => {
          return mutations.formatDate(date);
        })
        .join(", ");
    },
    crsDates() {
      if (this.crsDateRange.startDate) {
        let { startDate, endDate } = mutations.formatDates({
          ...this.crsDateRange,
        });
        return `${startDate} - ${endDate}`;
      }
      return "";
    },
  },
  methods: {
    async checkFile(event) {
      const { valid } = await this.$refs.file.validate(event);
      if (valid) {
        let file = event.target.files[0] || event.dataTransfer.files;
        this.selectFile(file);
      }
    },
    selectFile(file) {
      this.selectedFile.file = file;
      this.selectedFile.title = file.name.replace(/\.csv$/, "");
    },
    uploadFile() {
      this.isLoading = true;
      let formData = new FormData();
      formData.append("title", this.selectedFile.title);
      formData.append("file", this.selectedFile.file);
      this.$axios
        .post("reports/convert", formData)
        .then(({ data: { success, message, html } }) => {
          if (success) {
            this.downloadHTML(html);
          }
          this.isLoading = false;
          mutations.showToast({ success, message });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    downloadHTML(html) {
      let fileName = `${this.selectedFile.title}.html`;
      this.createLink(html, "text/html", fileName);
      this.resetHTMLForm();
    },
    generateCRSReport() {
      this.isLoading = true;
      this.$axios
        .get("reports/generateCRS", { params: this.crsDateRange })
        .then(({ data: { success, message, csvFile } }) => {
          if (success) {
            this.createLink(csvFile, "text/csv", "crs.csv");
          }
          this.isLoading = false;
          mutations.showToast({ success, message });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    generateXMLReport() {
      this.isLoading = true;
      let formData = new FormData();
      formData.append("title", this.selectedFile.title);
      formData.append("file", this.selectedFile.file);
      this.$axios
        .post("reports/generateXML", formData)
        .then(({ data: { success, message, title, xmlFile } }) => {
          if (success) {
            this.createLink(xmlFile, "text/xml", `${title}.xml`);
            this.resetXMLForm();
          }
          this.isLoading = false;
          mutations.showToast({ success, message });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    downloadCSV() {
      this.isLoading = true;
      this.$axios
        .get("reports/generate", { params: this.selectedCSVFile })
        .then(({ data: { success, message, csvFile } }) => {
          if (success) {
            let filename = `${this.selectedCSVFile.title}.csv`;
            this.createLink(csvFile, "text/csv", filename);
            this.resetCSVForm();
          }
          this.isLoading = false;
          mutations.showToast({ success, message });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    createLink(file, fileType, fileName) {
      const link = document.createElement("a");
      const uri = encodeURI(`data:${fileType};charset=utf-8,` + file);
      link.setAttribute("href", uri);
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    resetHTMLForm() {
      this.$nextTick(() => {
        this.selectedFile = {
          title: "",
          file: null,
        };
        this.$refs.HTMLReportForm.reset();
        document.getElementById("reportFile").value = null;
      });
    },
    resetCSVForm() {
      this.$nextTick(() => {
        this.selectedCSVFile = {
          title: "",
          dates: [],
        };
        this.dateRange = {
          startDate: null,
          endDate: null,
        };
        this.$refs.CSVReportForm.reset();
      });
    },
    resetCRSForm() {
      this.$nextTick(() => {
        this.crsDateRange = {
          startDate: null,
          endDate: null,
        };
        this.$refs.CRSReportForm.reset();
      });
    },
    resetXMLForm() {
      this.$nextTick(() => {
        this.selectedFile = {
          title: "",
          file: null,
        };
        this.$refs.XMLReportForm.reset();
        document.getElementById("reportXMLFile").value = null;
      });
    },
    selectDate({ startDate }) {
      let selectedDate = mutations.convertDate(startDate);
      if (!this.selectedCSVFile.dates.includes(selectedDate)) {
        this.selectedCSVFile.dates.push(selectedDate);
      }
    },
    selectCRSDate() {
      this.crsDateRange = mutations.convertDates(this.crsDateRange);
    },
  },
};
</script>
